var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper directory-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "directory-block"
  }, [_c('h2', [_vm._v(_vm._s(_vm.details.name))]), _c('ul', [_c('li', [_c('strong', [_vm._v("Phone:")]), _c('a', {
    attrs: {
      "href": 'tel:' + _vm.details.phone
    }
  }, [_vm._v(_vm._s(_vm.details.phone))])]), _c('li', [_c('strong', [_vm._v("Email:")]), _c('a', {
    attrs: {
      "href": 'mailto:' + _vm.details.email
    }
  }, [_vm._v(_vm._s(_vm.details.email))])])])])]), _vm._l(_vm.directoryList, function (list, index) {
    return _c('div', {
      key: index,
      staticClass: "col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-4"
    }, [_c('div', {
      staticClass: "directory-block-card"
    }, [_c('h3', [_vm._v(_vm._s(list.name))]), _c('span', [_c('strong', [_vm._v("Email:")]), _c('a', {
      attrs: {
        "href": 'mailto:' + list.email
      }
    }, [_vm._v(_vm._s(list.email))])]), list.details ? _c('ul', _vm._l(list.details, function (detail, index) {
      return _c('li', {
        key: index
      }, [_vm._v(" " + _vm._s(detail.title) + " ")]);
    }), 0) : _vm._e()])]);
  })], 2)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Directory")])]);
}]

export { render, staticRenderFns }