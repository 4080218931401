<template>
  <div class="page-wrapper directory-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Directory</h1>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="directory-block">
            <h2>{{ details.name }}</h2>
            <ul>
              <li>
                <strong>Phone:</strong>
                <a :href="'tel:' + details.phone">{{ details.phone }}</a>
              </li>
              <li>
                <strong>Email:</strong>
                <a :href="'mailto:' + details.email">{{ details.email }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div
          class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-4"
          v-for="(list, index) in directoryList"
          :key="index"
        >
          <div class="directory-block-card">
            <h3>{{ list.name }}</h3>
            <span
              ><strong>Email:</strong>
              <a :href="'mailto:' + list.email">{{ list.email }}</a></span
            >
            <ul v-if="list.details">
              <li v-for="(detail, index) in list.details" :key="index">
                {{ detail.title }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  name: "Directory",
  data() {
    return {
      details: {
        name: "",
        email: "",
        phone: "",
      },
      directoryList: [],
      breadcrumbItems: [{ title: "Directory", active: true }],
    };
  },
  methods: {
    async getDirectoryList() {
      let url = "v2/directory/get";
      let loader = this.$loading.show();
      await Api.get(url)
        .then((res) => {
          this.directoryList = res.data.data.directory_list;
          let { name, email, phone } = res.data.data;
          this.details = { name, email, phone };
        })
        .catch(() => {
          this.directoryList = [];
        })
        .finally(() => loader.hide());
    },
  },
  created() {
    this.getDirectoryList();
  },
};
</script>
<style src="../assets/css/directory.css" scoped></style>
